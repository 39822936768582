<template>
  <v-card flat rounded class="rounded-xl" outlined>
    <v-card-title class="d-block">
      <div>
        <v-row class="align-center">
          <v-col lg="2" cols="3">
            <v-img v-if="plan.image" :src="plan.image" :max-width="sizeIcon" />
            <v-icon v-else :size="sizeIcon" color="primary">
              local_activity
            </v-icon>
          </v-col>
          <v-col lg="10" cols="9">
            <v-row align="start" justify="space-between" class="pa-3">
              <div>
                <span
                  class="d-block body-2 grey--text text-decoration-line-through"
                >
                  {{ oldPlanName }}
                </span>
                <span>{{ plan.name }}</span>
              </div>
              <v-icon
                v-if="plan.isRecommended"
                class="ml-1"
                color="secondary"
                size="30"
              >
                verified
              </v-icon>
            </v-row>
            <old-price-text v-if="oldPrice" :price="oldPrice" />
            <price-text :price="plan.price" />
          </v-col>
        </v-row>
      </div>
    </v-card-title>
    <v-card-text>
      <v-divider />
      <benefits-list :benefits="plan.benefits" class="my-5" />
    </v-card-text>
  </v-card>
</template>

<script>
import OldPriceText from "@/domains/subscriptions/presentation/components/OldPriceText";
export default {
  name: "PlanCardView",
  components: {
    OldPriceText,
    BenefitsList: () =>
      import("@/domains/subscriptions/presentation/components/BenefitsList"),
    PriceText: () =>
      import("@/domains/subscriptions/presentation/components/PriceText"),
  },
  props: {
    plan: {
      type: Object,
      default: () => {},
    },
    oldPrice: {
      type: String,
      default: "",
    },
    oldPlanName: {
      type: String,
      default: "",
    },
  },
  computed: {
    sizeIcon() {
      return this.$vuetify.breakpoint.xs ? 70 : 80;
    },
  },
};
</script>

<style scoped></style>
