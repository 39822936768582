<template>
  <div class="d-flex align-center grey--text text-decoration-line-through">
    <span class="body-2 price">{{ price }}</span>
    <span class="caption">/mês</span>
  </div>
</template>

<script>
export default {
  name: "OldPriceText",
  props: {
    price: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped></style>
